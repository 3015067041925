<template>
  <div>
    <Table stripe :data="historyList" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getResourceHistoryPage } from '@/api/product/resource'
export default {

  computed: {
    detailId () {
      return this.$store.state.equipment.detailId
    }
  },
  data () {
    return {
      total: 0,
      showFiles: false,

      query: {
        pageNumber: 1,
        pageSize: 15
      },
      historyList: [],
      tableColumns: [
        {
          title: '变更描述',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: { marginRight: '5px' }
                },
                '标记为：' + (params.row.enabled === 1 ? '可用' : '不可用')
              )
            ])
          }
        }, {
          title: '变更备注',
          key: 'desc'
        }, {
          title: '变更时间',
          key: 'createTime'
        },
        {
          title: '变更人',
          key: 'userName'
        }
      ]
    }
  },
  created () {
    if (this.detailId) {
      this.query.resourceId = this.detailId
      this.initHistory()
    }
  },
  methods: {
    // 初始化资产选项，并默认选中第一条
    initHistory () {
      getResourceHistoryPage(this.query).then((res) => {
        this.historyList = res.list
        this.total = res.totalRow
      })
    },

    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initHistory()
    }
  }
}
</script>
